import React from "react"
import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Small from "../../components/small"
import Stack from "../../components/stack"

const Katalog24 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Robert Schindel: So ohne Genossen zu sein" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Robert Schindel: So ohne Genossen zu sein",
                link: "/k/24",
              },
            ]}
          />
          <PageTitle>Robert Schindel: So ohne Genossen zu sein</PageTitle>
          <Constrain>
            <Stack space={6}>
              <AudioPlayer src="katalog/24-robert-schindel" />
              <Small color="whisper">
                Aus: Robert Schindel, Fremd bei mir selbst. Die Gedichte (1965 -
                2003) © Suhrkamp Verlag Frankfurt am Main 2004.
              </Small>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog24
